import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper.scss';
//import 'swiper/modules/navigation/navigation.scss';

document.addEventListener('DOMContentLoaded', () => {
    
    const reviews = document.querySelector('.js-reviewsSwiper');

    const reviewsCarousel = function() {
        const swiper = new Swiper(reviews, {
            modules: [Navigation, Pagination],
            autoHeight: true,
            centeredSlides: true,
            slidesPerView: 'auto',
    	    breakpoints: {
                769: {
                    autoHeight: false,
                    centeredSlides: true,
                    spaceBetween: 35
                },
            },
            navigation: {
                nextEl: document.querySelector('.swiper-button-next.reviews'),
                prevEl: document.querySelector('.swiper-button-prev.reviews')
            },

            pagination: {
                el: reviews.parentNode.querySelector('.swiper-pagination'),
                clickable: true
            },

            touchMoveStopPropagation: false,
            slidesOffsetAfter: 30,
            slidesPerView: 'auto',
    	    spaceBetween: 20,
            speed: 800
        });
    };


    window.carousels = () => {
        reviews ? reviewsCarousel() : false;
    }

}, false)
