import css from './sass/style.scss';

require('./js/accordion.js');
require('./js/anims.js');
require('./js/buy.js');
require('./js/carousels.js');
require('./js/cookies.js');
require('./js/fonts.js');
require('./js/glightbox.js');
require('./js/init.js');
//require('./js/inview.js');
require('./js/ismobile.js');
require('./js/lazy.js');
// require('./js/map.js');
require('./js/maptiler.js');
require('./js/nav.js');
require('./js/polyfills.js');
require('./js/smoothscroll.js');
require('./js/tabs.js');
require('./js/validate.js');
