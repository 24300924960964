import validate from 'validate.js';
import { AsYouType } from 'libphonenumber-js';
import { parsePhoneNumberFromString as parseMin } from 'libphonenumber-js/max'

document.addEventListener('DOMContentLoaded', () => {

    const init = function(el, constraints) {

        const form = el;
        
        form.addEventListener("submit", function(ev) {
            ev.preventDefault();
            handleFormSubmit(form);
        });

        // Hook up the inputs to validate on the fly
        const inputs = form.querySelectorAll("input, textarea, select");

        for (let i = 0; i < inputs.length; ++i) {
            inputs.item(i).addEventListener("change", function(ev) {
                const errors = validate(form, constraints, { fullMessages: false }) || {};
                console.clear();
                console.log(errors);
                showErrorsForInput(this, errors[this.name])
            });
        }

        function handleFormSubmit(form, input) {
            // validate the form against the constraints
            const errors = validate(form, constraints, { fullMessages: false });
            console.log(errors);
            // then we update the form to reflect the results
            showErrors(form, errors || {});
            if (!errors) {
                showSuccess();
            } else {
                // console.log(errors);
            }
        };
        
        // Updates the inputs with the validation errors
        function showErrors(form, errors) {
            // We loop through all the inputs and show the errors for that input
            _.each(form.querySelectorAll("[required]"), function(input) {
            // Since the errors can be null if no errors were found we need to handle
            // that
            showErrorsForInput(input, errors && errors[input.name]);
            });
        };

        // Shows the errors for a specific input
        function showErrorsForInput(input, errors) {
            // This is the root of the input
            const formGroup = closestParent(input.parentNode, "form-group")
            // Find where the error messages will be insert into
            , messages = formGroup.querySelector(".js-messages");
            // First we remove any old messages and resets the classes
            resetFormGroup(formGroup);
            // If we have errors
            if (errors) {
                // we first mark the group has having errors
                formGroup.classList.add("has-error");
                // then we append all the errors
                _.each(errors, function(error) {
                    if (messages) {
                        addError(messages, error);
                    }
                });
            } else {
                // otherwise we simply mark it as success
                formGroup.classList.add("has-success");
            }
        };

        // Recusively finds the closest parent that has the specified class
        function closestParent(child, className) {
            if (!child || child == document) {
                return null;
            }
            if (child.classList.contains(className)) {
                return child;
            } else {
                return closestParent(child.parentNode, className);
            }
        };
        
        function resetFormGroup(formGroup) {
            // Remove the success and error classes
            formGroup.classList.remove("has-error");
            formGroup.classList.remove("has-success");
            // and remove any old messages
            _.each(formGroup.querySelectorAll(".help-block.error"), function(el) {
                el.parentNode.removeChild(el);
            });
        }
        
        // Adds the specified error with the following markup
        // <p class="help-block error">[message]</p>
        function addError(messages, error) {
            const block = document.createElement("p");
            block.classList.add("help-block");
            block.classList.add("error");
            block.innerText = error;
            messages.appendChild(block);
        }

        function startAjax() {
            form.querySelector('button').style.display = 'none';
            form.querySelector('.lds-ripple').style.display = 'inline-flex';
        }

        function finishAjax() {
            form.querySelector('button').style.display = 'inline-flex';
            form.querySelector('.lds-ripple').style.display = 'none';
        }

        const formToJSON = (elements) =>
          [].reduce.call(
            elements,
            (data, element) => {
                if (element.type === 'checkbox') {
                    element.checked ? data[element.name] = element.value : data[element.name] = '';
                } else {
                    element.name == 'phone' ? data[element.name] = element.value.replaceAll(" ", "") :
                        data[element.name] = element.value;
                }
              return data;
            },
            {},
        );
          
        function submitForm() {
            let xmlHttp = new XMLHttpRequest(), 
                url = form.getAttribute('action');

            xmlHttp.onload = function() {
                console.log(xmlHttp.status);
                if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
                    
                    //thanks.classList.add('is-active');
/*
                    setTimeout(function() {
                        thanks.classList.add('is-visible');
                        form.reset();
                    }, 10)
*/
                    
                    //console.log(xmlHttp.responseText);
                    showMessage('Dziękujemy za zaufanie. Wkrótce skontaktujemy się z Tobą celem ustalenia szczegółów realizacji zamówienia.');
                } else {
                    showMessage('Wystąpił błąd w wysyłaniu formularza.');
                }
               finishAjax();
            };
        
            console.clear();
            xmlHttp.open('post', url, true);
            xmlHttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            xmlHttp.send(JSON.stringify(formToJSON(form.elements)));
            console.log(JSON.stringify(formToJSON(form.elements)));
        }

        function showMessage(message) {
            let container = form.querySelector('.js-messages--summary');
            container.style.display = 'block';
            container.innerHTML = message;
        }
        
        function showSuccess() {
            startAjax();
            submitForm();
        }
        
        // Custom Validate for telephone
        validate.validators.telCheck = function(value, options, key, attributes) {
            if (value) {
                let phoneNumber = parseMin(value, 'PL');

                if (phoneNumber.isValid()) {
                    return;
                } else {
                    return 'Podaj poprawny numer';
                }
            }
        };
    };
    
    const telFormat = function() {
        let inputs = document.getElementsByTagName('input');

        for(let i = 0; i < inputs.length; i++) {
            if(inputs[i].type.toLowerCase() == 'tel') {               
               inputs[i].addEventListener("keyup", event => {
                    let val_old = inputs[i].value,
                        val_new = new AsYouType('PL').input(inputs[i].value);
                    inputs[i].value = val_new;                    
                });
            }
        }
    }();
    
    
    // Init
    
    const buyform = function() {
        const constraints = {      
            gdpr: {
                presence: { message: "Zaakceptuj zgodę" },
                inclusion: {
                    within: [true],
                    message: "^Zaakceptuj zgodę"
                }
            },
            
            name_surname: {
                presence: { message: "Pole nie może być puste" },
                length: {
                    maximum: 255,
                    minimum: 3,
                    message: 'Min. 3 znaki'
                },
                format: {
                    pattern: "[a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ0-9 ,.'-]+",
                    flags: "i",
                    message: "Tylko znaki a-z i 0-9"
                }
            },
            
            phone: {
                presence: { message: "Pole nie może być puste" },
                telCheck: "Błędny numer"
            },
            
            post_code: {
                presence: { message: "Pole nie może być puste" },
            },

            privacy_policy: {
                presence: { message: "Zaakceptuj zgodę" },
                inclusion: {
                    within: [true],
                    message: "^Zaakceptuj zgodę"
                }
            }  
        }; 
        
        init(document.getElementById('buyform'), constraints);
    };
    
    document.getElementById('buyform') ? buyform() : false;

}, false);
