document.addEventListener('DOMContentLoaded', () => {
    
    const el = document.querySelector('#buyform');

    const buy = () => {
        const sample = document.querySelector('#is_sample'),
              quantity = document.querySelector('.js-quantity'),
              palette_price = el.dataset.palette_price,
              palette_weight = el.dataset.palette_weight,
              bag_price = Math.ceil(el.dataset.bag_price),
              bag_weight = el.dataset.bag_weight,
              num_field = quantity.querySelector('.js-num'),
              weight_field = quantity.querySelector('.js-weight');
              
        let index = 1, is_sample = false, price;

        const calculate_price = (factor) => {
            is_sample ? price = bag_price : price = palette_price;
            console.clear();
            console.log(palette_price);
            el.querySelector('.js-pricenetto').innerHTML = (price * factor).toFixed(0) + ' pln';
            el.querySelector('.js-pricebrutto').innerHTML = Math.ceil(Number(price) + Number(price) * 0.23) * factor + ' pln';
        }
        
        const calculate_weight = (factor) => {
            is_sample ? weight = bag_weight : weight = palette_weight;
            weight_field.setAttribute('value', weight * factor + ' kg');
        }

        const quantityBlock = () => {
            index = num_field.getAttribute('value');
            
            num_field.setAttribute('value', index);	
            calculate_weight(index);
        		
        	const changeNum = () => {
        	   num_field.setAttribute('value', index);
        	   calculate_price(index);
        	   calculate_weight(index);
        	};

        	quantity.querySelector('.js-minus').addEventListener('click', () => {
            	if (index > 1) {
        			index --;
        			changeNum();
                }
        	});
        	
            quantity.querySelector('.js-plus').addEventListener('click', () => {
                if (is_sample) {
                    index < 10 ? index ++ : false;
                } else {
                    index ++;
                }
        		
        		changeNum();
        	});
        };
        
        const sampleBlock = () => {
            sample.addEventListener('change', function(e) {
                is_sample = e.target.checked;
                index = 1;

                is_sample ? document.querySelector('.js-title').innerHTML = el.dataset.title_sample : 
                            document.querySelector('.js-title').innerHTML = el.dataset.title_default;

                num_field.setAttribute('value', index);
                calculate_price(index);
                calculate_weight(index);
            })
        }
                
        sample ? sampleBlock() : false;
        quantity ? quantityBlock() : false;
        calculate_price(1);
    }

	el ? buy() : false;
	

}, false);
