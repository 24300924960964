
document.addEventListener('DOMContentLoaded', () => {
    
    const el = document.getElementById('map');
    // let mapenable = false, int;

    const initMap = function() {

        const key = '9ArLielz29KV3ClYUL6d';

        var map = L.map('map', {
            center: [51.49964,21.672013],
            zoom: 10,
            scrollWheelZoom: false
        });

        L.tileLayer(`https://api.maptiler.com/maps/d8fa1eef-77ba-4294-a927-536251522843/{z}/{x}/{y}.png?key=${key}`,{ //style URL
            tileSize: 512,
            zoomOffset: -1,
            minZoom: 1,
            attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
            crossOrigin: true
        }).addTo(map);

        var myIcon = L.icon({
            iconUrl: 'img/map/d23e182dcad5526105e2480dab96f508.svg',
            iconSize: [39, 63],
            iconAnchor: [20, 63],
            // popupAnchor: [-3, -76],
        });

        var marker = L.marker([51.49964,21.672013], {icon:myIcon}).addTo(map);

        var circle = L.circle([51.49964,21.672013], {
            color: '#11442f',
            fillColor: '#11442f',
            fillOpacity: 0.5,
            radius: 40000
        }).addTo(map);
    };

    el ? initMap() : false;
    
}, false);


