import { gsap, Power1 } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger);
    
    window.anims = () => {               
        gsap.utils.toArray(".js-fadeInChildren > *").forEach(function(section) {
            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                    toggleActions: "play complete complete reset",
                },
                opacity: 0, 
                duration: 1,
                y: 50
            });
        });
               
        if (document.querySelector('.c-reviews')) {
            gsap.from(document.querySelector('.js-reviewsSwiper'), {
                scrollTrigger: {
                    trigger: document.querySelector('.c-reviews'),
                    scrub: 2.2,
                    start: 'top bottom',
                    end: 'top center'
                },      
                xPercent: 15
            });
        };
    };

}, false)
